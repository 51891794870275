body.login {
  background-color: #C0EDFE;
}

body.login div#login h1 a {
  background-image: url('../images/applied-workplace-logo.svg');
  background-size: 100%;
  padding-bottom: 20px;
  width: 100%;
  margin: 0;
}